@import '~app-variables.scss';

:global(.dfoContentSwitcherTag.ant-menu) {
  font-size: 1rem;

  :global(.ant-menu-item) > span {
    padding-left: $ctr-half-gutter-size;
  }

  :global(.ant-menu-item .dfoIcon) {
    font-size: 1.5rem;
  }

  @include for-tablet-down {
    display: flex;

    :global(.ant-menu-item) {
      flex: 1;
      font-size: 1.5rem;
      text-align: center;

      &:not(:first-child) {
        border-left: $ctr-border-width-base solid $ctr-border-color-split;
      }

      > span {
        display: none;
      }
    }
  }
}
