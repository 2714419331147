@import '~app-variables.scss';

.ctrComp {
  display: flex;

  &__logo {
    width: 140px;
  }

  &__content {
    flex: 1;
  }

  @include for-tablet-down {
    flex-direction: column;
  }

  &__horizontalItem {
    flex-direction: row !important;
    align-items: center !important;
    :global .ant-form-item-label {
      padding: 0 !important;
      padding-right: $ctr-gutter-size !important;
    }
  }
}
