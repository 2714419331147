@import "~app-variables.scss";

.ctrSimpleSearchBox {
  //TODO bich - temporary solution
  :global {
    .ant-btn-primary {
      margin-left: 0;
      background-color: $ctr-secondary-color !important;
      border-color: $ctr-secondary-color !important;
    }
  }

  &__hideSuffix {
    :global {
      .ant-input-suffix {
        display: none !important;
      }
    }
  }
}
