@import '~app-variables.scss';

.ctrComp {
  $prefix: &;
  $textColor_subItem: #2f3133;
  $textColor_mainItem: $ctr-color-white;

  font-size: $ctr-font-size-md;
  font-weight: $ctr-font-weight-bold;
  display: inline-flex;
  align-items: center;
  max-width: 100%;

  span {
    font-weight: $ctr-font-weight-bold !important;
  }

  &__icon {
    margin-right: $ctr-gutter-size;
    font-size: 1.4em;
  }

  &__icon,
  &__text,
  &__text:hover {
    color: $textColor_mainItem;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__text,
  &__text:hover {
    text-decoration: none;
  }

  &__subItem {
    #{$prefix}__icon,
    #{$prefix}__text,
    #{$prefix}__text:hover {
      color: $textColor_subItem !important;
    }
  }

  &--inline {
    #{$prefix}__icon,
    #{$prefix}__text,
    #{$prefix}__text:hover {
      color: $textColor_mainItem !important;
    }
  }
}
