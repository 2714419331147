//Ref: https://getbootstrap.com/docs/4.4/layout/overview/#containers (need to confirm to design team)
// mobile: extra small, small (<576px)
// table: medium (between 576px and 768px)
// desktop: large & extra large (> 768px)

@mixin for-phone-only {
  @media (max-width: #{$ctr-screen-sm-min - 1px}) { @content; }
}

@mixin for-phone-up {
  @media (min-width: #{$ctr-screen-sm-min}) { @content; }
}

@mixin for-tablet-only {
  @media (min-width: #{$ctr-screen-sm-min}) and (max-width: #{$ctr-screen-md-min - 1px}) { @content; }
}

@mixin for-desktop-up {
  @media (min-width: $ctr-screen-md-min) { @content; }
}

@mixin for-tablet-down {
  @media (max-width: #{$ctr-screen-md-min - 1px}) { @content; }
}
