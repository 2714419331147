//@import "~app-variables.scss";
@import '~app-variables.scss';


.ctrComp {
  height: $ctr-layout-header-height;
  display: block;

  $parent: &;


  padding: $ctr-gutter-size/2 $ctr-gutter-size*1.5 ;


  &__image {
    height: 100%;
    width: 100%;
    background-image: url(~ctrwowShared/assets/img/img_logo_white.png);
    background-repeat: no-repeat;
    background-size: contain;
  }

  &__mini {
    padding: $ctr-gutter-size/2;
    #{$parent}__image {
      background-image: url(~ctrwowShared/assets/img/logo-white.svg);
      background-position: center;
    }
  }
}
