//clone from [./_breakpoints.scss]
.for-phone-only(@rules) {
  @media (max-width: @ctr-screen-sm-min - 1px) { @rules(); }
}

.for-tablet-only(@rules) {
  @media (min-width: @ctr-screen-sm-min) and (max-width: @ctr-screen-md-min - 1px) { @rules(); }
}

.for-desktop-up(@rules) {
  @media (min-width: @ctr-screen-md-min) { @rules(); }
}

.for-tablet-down(@rules) {
  @media (max-width: @ctr-screen-md-min - 1px) { @rules(); }
}
