@import '~app-variables.scss';

.dfoComp {
  $parent: &;

  &__avatar {
    border: $ctr-border-width-base solid $ctr-border-color-base;
    color: $ctr-secondary-color;
    margin-right: 8px;
  }

  &--inline {
    display: inline-flex;
    align-items: center;

    #{$parent}__name {
      flex: 1;
    }
  }
}
