@import '~app-variables.scss';

.ctrComp {
  $itemHeight__vertical: 45px;
  $itemHeight__horizontal: 55px;
  $prefix: &;
  $selectedItemBg: #052266;
  $selectedItem_subItemBg: rgba(0, 0, 0, .08);
  $selectedItem_HoverBg: rgba(0, 0, 0, .1);

  $textColor_menuItem: $ctr-color-white;

  background-color: transparent !important;
  border-right: 0;

  &--horizontal,
  &--vertical {
    #{$prefix}__subMenu__active {
      background-color: $selectedItemBg !important;

      :global(.ant-menu-submenu-title) {
        opacity: unset !important;
      }
    }
  }

  //&--vertical {
  //  :global {
  //    .ant-menu-submenu,
  //    .ant-menu-submenu-title,
  //    .ant-menu-item {
  //      text-overflow: initial !important;
  //      padding-top: 5px;
  //    }
  //  }
  //}


  :global {
    .ant-menu-root {
      background: none !important;

      .ant-menu-item, > .ant-menu-submenu {
        color: $textColor_menuItem;
      }
      .ant-menu-item.ant-menu-item-selected {
        background-color: $selectedItemBg !important;
        opacity: unset !important;
      }
    }

    .ant-menu, .ant-menu-item a {
      font-size: 16px;
      font-weight: 400;
    }

    .ant-menu-inline {
      &.ant-menu, .ant-menu-item a {
        color: $textColor_menuItem;
      }
    }

    .ant-menu-item-icon {
      font-size: 1.2em !important;
      margin-bottom: -3px !important;
    }

    .ant-menu-item,
    .ant-menu-submenu-title {
      opacity: 0.7;
    }

    .ant-menu-item-disabled {
      opacity: 0.2 !important;
      cursor: default !important;
    }

    .ant-menu-item::after {
      content: unset !important;
    }

    .ant-menu-item:hover,
    .ant-menu-item-active,
    .ant-menu-submenu-active {
      background-color: $selectedItem_HoverBg !important;
    }


    .ant-menu.ant-menu-sub.ant-menu-inline {
      background-color: transparent !important;
    }

    //INLINE STYLE
    .ant-menu-inline,
    .ant-menu-vertical,
    .ant-menu-vertical-left {
      border-right: none !important;
    }
  }
}
