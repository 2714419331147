// keyframes mixin
@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

@mixin animation ($animation: null, $duration: null, $timingFunction: null, $delay: null, $iteration: null) {
  -webkit-animation-name: $animation;
  -webkit-animation-duration: $duration;
  -webkit-animation-timing-function: $timingFunction;
  -webkit-animation-delay: $delay;
  -webkit-animation-iteration-count: $iteration;

  -moz-animation-name: $animation;
  -moz-animation-duration: $duration;
  -moz-animation-timing-function: $timingFunction;
  -moz-animation-delay: $delay;
  -moz-animation-iteration-count: $iteration;

  animation-name: $animation;
  animation-duration: $duration;
  animation-timing-function: $timingFunction;
  animation-delay: $delay;
  animation-iteration-count: $iteration;
}

// use of keyframes mixin
@include keyframes(animScale) {
  0% {
    opacity: 0;
    transform: translate3d(0, 40px, 0) scale3d(0.1, 0.6, 1);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  }
}

@include keyframes(load) {
  0% {
    opacity: 0;
    transform: translateY(-97px);
  }

  15% {
    opacity: 0;
    transform: translateY(-97px);
  }

  30% {
    opacity: 1;
    transform: translateY(0);
  }

  70% {
    opacity: 1;
    transform: translateY(0);
  }

  85% {
    opacity: 0;
    transform: translateY(97px);
  }

  100% {
    opacity: 0;
    transform: translateY(97px);
  }
}

