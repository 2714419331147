@import '~app-variables.scss';

.dfoComp {

  &__avatarWrapper {
    display: flex;
    zoom: 1.5;
    flex-direction: column;
    justify-items: stretch;
    align-items: center;
    padding-bottom: $ctr-gutter-size;

    :global {
      .name {
        font-weight: $ctr-font-weight-bold;
        padding-top: $ctr-half-gutter-size;
      }
    }
  }

  :global {
    .ant-form-item {
      label {
        margin-bottom: 0;
      }
    }
  }
}
